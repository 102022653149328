// Config imports
import '../css/style.css';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Global initialization flag
let isInitialized = false;

// Constants and configurations
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

// Initialize Firebase (only once)
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Track auth listener to prevent duplicates
let authListener = null;

// API base URL configuration
const API_BASE_URL = '/app';

/**
 * API Helper for making HTTP requests
 * @namespace api
 */
const api = {
  /**
   * Make a GET request to the API
   * @param {string} endpoint - The API endpoint to call
   * @returns {Promise<any>} The JSON response from the API
   */
  async get(endpoint) {
    try {
      const url = `${API_BASE_URL}/${endpoint}`;
      console.log('Attempting API call to:', url);

      const response = await fetch(url);
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers));

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('API Request failed:', error);
      throw error;
    }
  },

  /**
   * Make a POST request to the API
   * @param {string} endpoint - The API endpoint to call
   * @param {Object} data - The data to send in the request body
   * @returns {Promise<any>} The JSON response from the API
   */
  async post(endpoint, data) {
    try {
      const response = await fetch(`/app/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('API Request failed:', error);
      throw error;
    }
  },
};

// Auth state observer (with cleanup)
function initializeAuth() {
  // Remove previous listener if it exists
  if (authListener) {
    authListener();
  }

  // Set up new listener
  authListener = onAuthStateChanged(auth, user => {
    if (user) {
      // eslint-disable-next-line no-console
      console.log('User is signed in');
      // eslint-disable-next-line no-console
      console.log('Email:', user.email);
      // eslint-disable-next-line no-console
      console.log('UID:', user.uid);
    } else {
      // eslint-disable-next-line no-console
      console.log('User is signed out');
    }
  });
}

// Test API connection (only once)
let apiTested = false;
function testApi() {
  if (apiTested) return;
  apiTested = true;

  api
    .get('hello')
    .then(data => {
      // eslint-disable-next-line no-console
      console.log('API Response:', data);
    })
    .catch(error => {
      console.error('API Error:', error);
    });
}

// Initialize everything (only once)
function initialize() {
  if (isInitialized) return;
  isInitialized = true;

  // Initialize auth
  initializeAuth();

  // Test API
  testApi();
}

// Call initialize
initialize();
